import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate here



interface CreateRemixButtonProps {
    fabricCanvas: fabric.Canvas;
    baseProduct: string;
    personalisation: string;
    canvasId: number; // Add this line
  }

const BASE_URL = process.env.REACT_APP_AWS_BUCKET_URL
  const active_url = process.env.REACT_APP_FLOSSY_API_URL;

const CreateRemixButton: React.FC<CreateRemixButtonProps> = ({ fabricCanvas, baseProduct, personalisation, canvasId }) => {
  const navigate = useNavigate();
  const handleCreateRemixButtonClick = async () => {
    const token = 'a24e549e62d35efe610192dd98355e96e1888ceb'; 

    // Convert the fabricCanvas to an image and post it to the /upload endpoint
    fabricCanvas.discardActiveObject().renderAll();
    const canvasImage = fabricCanvas.toDataURL();
    if (!canvasImage) {
      return;
    }

    let imageUrl;
    try {
      const uploadResponse = await axios.post(`${active_url}/upload/`, {
        imageData: canvasImage
      }, {
        headers: { 'Authorization': `Token ${token}` }
      });
    
      imageUrl = BASE_URL + uploadResponse.data.imageUrl; // Concatenate base URL with filename
      console.log('Image uploaded successfully:', imageUrl);
    } catch (error) {
      return;
    }


    const data = {
      user: 1, // You need to provide the user id here
      canvas: canvasId,
      base_product: Number(baseProduct),
      remixed_canvas_image_url: imageUrl,
      canvas_image_url: imageUrl,
      personalisation_text: personalisation
    };
    
    
    axios.post(`${active_url}/remix/`, data, {
      headers: { 'Authorization': `Token ${token}` }
    })
    .then((response) => {
      // handle success
      navigate('/create-remix', { state: {user:1, baseProduct: response.data.base_product, remixId: response.data.remix_id, remixedMockUpImageUrl: response.data.remixed_mock_up_image_url, canvasId: canvasId } });    })
    .catch(error => {
      console.error('Error:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
    });
  };

  return (
    <button className="btn btn-dark choose-form-button show-button" onClick={handleCreateRemixButtonClick}>Final Step {'>'} Create Remix ✨</button>
  );
};

export default CreateRemixButton;