import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import ExportButton from './ExportButton';
import { LayerConfig } from './types';
import ShareButton from './ShareButton';
import './CanvasDetail.css';
// import BackgroundGenerator from './BackgroundGenerator';




interface CanvasProps {
  personalisation: string;
  canvasRef: React.MutableRefObject<fabric.Canvas | null>;
  layersConfig: LayerConfig[];
  canvasUser: string | null;
  canvasTitle: string | null;

  }




const Canvas: React.FC<CanvasProps> = ({ canvasRef, layersConfig: initialLayersConfig, personalisation, canvasTitle, canvasUser }) => {

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [selectedLayerId, setSelectedLayerId] = useState<string | null>(null);
  const [canvasColor, setCanvasColor] = useState('#ffffff'); // default to white
  const [layersConfig, setLayersConfig] = useState(initialLayersConfig);
  const [isCanvasReady, setCanvasReady] = useState(false);

  
  const addLayer = (canvas: fabric.Canvas, layer: LayerConfig, personalisation: string): Promise<void> => {

    return new Promise((resolve, reject) => {
      if (layer.type === 'background' && layer.source) {
        setBackgroundImageFunc(canvas, layer.source);

      } else if (layer.type === 'image' && layer.source) {
        const existingImageLayer = canvas.getObjects('image').find(obj => obj.name === layer.id) as fabric.Image; 
        if (existingImageLayer) {
          // If an image layer already exists, update it
          existingImageLayer.setSrc(layer.source, () => {
            canvas.renderAll();
            resolve();
          }, { left: layer.position?.left || 0, top: layer.position?.top || 0 });
        } else {
          // If no image layer exists, create a new one
          fabric.Image.fromURL(layer.source, (img) => {
            if (img) {
              img.set({ 
                left: layer.position?.left || 0, 
                top: layer.position?.top || 0,
                name: layer.id // Set a unique id for the layer
              });
              if (layer.scaleToCanvas && canvas.width && canvas.height) {
                img.scaleToWidth(canvas.width);
                img.scaleToHeight(canvas.height);
              }
              canvas.add(img);
              resolve();
            } else {
              reject(new Error('Failed to load image'));
            }
          });
        } 


      } else if (layer.type === 'text' && personalisation !== null && personalisation !== undefined && canvas) {
        const fontFamily = layer.fontFamily || 'Arial';
        document.fonts.load(`1em ${fontFamily}`).then(() => {
          const existingTextLayer = canvas.getObjects('text').find(obj => obj.name === layer.id) as fabric.IText;
          if (existingTextLayer) {
            // If a text layer already exists, update its text
            let transformedText = personalisation;
            if (layer.lockUpperCase) {
              transformedText = personalisation.toUpperCase();
            } else if (layer.lockLowerCase) {
              transformedText = personalisation.toLowerCase();
            }
            // Add the prefix if it exists
            if (layer.prefix) {
              transformedText = layer.prefix + transformedText;
            }
            // Add the suffix if it exists
            if (layer.suffix) {
              transformedText += layer.suffix;
            }
            existingTextLayer.set({ text: transformedText });
            canvas.renderAll();
            resolve();
          } else {
            // If no text layer exists, create a new one
            let left: number, top: number;
            const layerLeft: string | number | undefined = layer.position?.left;
            const layerTop: string | number | undefined = layer.position?.top;
            if (typeof layerLeft === 'string' && layerLeft === "center" && canvas.width) {
              left = canvas.width / 2;
            } else {
              left = typeof layerLeft === 'number' ? layerLeft : 0;
            }
            if (typeof layerTop === 'string' && layerTop === "center" && canvas.height) {
              top = canvas.height / 2;
            } else {
              top = typeof layerTop === 'number' ? layerTop : 0;
            }
            // Add the suffix if it exists
            let finalText = personalisation;
            if (layer.prefix) {
              finalText = layer.prefix + finalText;
            }
            if (layer.suffix) {
              finalText += layer.suffix;
            }
            const baseFontSize = layer.fontSize || 16; // This is the base font size in pixels
            const canvasWidth = canvas.width || 500; // Provide a default value for canvas.width
            const canvasHeight = canvas.height || 500; // Provide a default value for canvas.height
            const canvasSize = Math.min(canvasWidth, canvasHeight); // Use the smaller dimension of the canvas to calculate the font size
            const fontSize = (baseFontSize / 500) * canvasSize; // Calculate the font size in pixels based on the size of the canvas
            
            const textObject = new fabric.Text(finalText, {
              left: left,
              top: top,
              fill: layer.color || 'black',
              fontFamily: fontFamily,
              originX: layer.originX || 'top',
              originY: layer.originY || 'left',
              lockMovementX: layer.lockMovementX || false,
              lockMovementY: layer.lockMovementY || false,
              fontSize: fontSize, // Use the calculated font size
              name: layer.id, // Set a unique id for the layer
              selectable: true,
              width: canvas.width || 200,
            });
            canvas.add(textObject);
            resolve();
          }
        }).catch((error) => {
          reject(new Error(`Failed to load font: ${error.message}`));
        });
      } else {
        reject(new Error('Invalid layer type'));
      }
    });
  };


    // Step 1: Create the guidelines
    const [, setVerticalGuide] = useState<fabric.Line | null>(null);
    const [, setHorizontalGuide] = useState<fabric.Line | null>(null);
    const verticalGuideRef = useRef<fabric.Line | null>(null);
    const horizontalGuideRef = useRef<fabric.Line | null>(null);
    
    useEffect(() => {
      if (canvasRef.current && canvasRef.current.height && canvasRef.current.width) {

        const canvasCenterX = canvasRef.current.width / 2;
        const canvasCenterY = canvasRef.current.height / 2;
    
        const newVerticalGuide = new fabric.Line([canvasCenterX, 0, canvasCenterX, canvasRef.current.height], {
          stroke: '#fff',
          strokeWidth: 1,
          selectable: false,
          evented: false,
          strokeDashArray: [5, 5],
          visible: false,
        });
        const newHorizontalGuide = new fabric.Line([0, canvasCenterY, canvasRef.current.width, canvasCenterY], {
          stroke: '#fff',
          strokeWidth: 1,
          selectable: false,
          evented: false,
          strokeDashArray: [5, 5],
          visible: false,
        });
        canvasRef.current.add(newVerticalGuide, newHorizontalGuide);


        setVerticalGuide(newVerticalGuide);
        setHorizontalGuide(newHorizontalGuide);
        verticalGuideRef.current = newVerticalGuide;
        horizontalGuideRef.current = newHorizontalGuide;
      }
    }, [canvasRef.current]);

  // New function to handle guidelines
  const handleGuidelines = (selectedObject: fabric.Object) => {
    // Get the object's position and dimensions
    let left = 0, top = 0, snapDistance = 10;
    // Get the object's position and dimensions
    if (
      typeof selectedObject.left === 'number' &&
      typeof selectedObject.top === 'number' &&
      typeof selectedObject.scaleX === 'number' &&
      typeof selectedObject.scaleY === 'number' &&
      typeof selectedObject.width === 'number' &&
      typeof selectedObject.height === 'number'
    ) {
      left = selectedObject.left;
      top = selectedObject.top;
      snapDistance = 10;
  
    }
  
    if (canvasRef.current && canvasRef.current.width && canvasRef.current.height) {
      // Calculate the object's center
      const objectCenterX = left 
      const objectCenterY = top 

      // Calculate the canvas's center
      const canvasCenterX = canvasRef.current.width / 2;
      const canvasCenterY = canvasRef.current.height / 2;

      // Check if the object is the active object
      if (canvasRef.current.getActiveObject() === selectedObject) {

        const isVerticallyCentered = Math.abs(objectCenterX - canvasCenterX) < snapDistance;
        const isHorizontallyCentered = Math.abs(objectCenterY - canvasCenterY) < snapDistance;

        if (isVerticallyCentered) {

          // selectedObject.set({ left: canvasCenterX - width / 2 });
          selectedObject.set({ left: canvasCenterX });

          if (verticalGuideRef.current) {
            verticalGuideRef.current.set({ stroke: '#B7cbfb' });
            setVerticalGuide(verticalGuideRef.current);
          }
        } else {
          if (verticalGuideRef.current) {
            verticalGuideRef.current.set({ stroke: 'red' });
            setVerticalGuide(verticalGuideRef.current);
          }
        }
        
        if (isHorizontallyCentered) {
          selectedObject.set({ top: canvasCenterY });

          if (horizontalGuideRef.current) {
            horizontalGuideRef.current.set({ stroke: '#B7cbfb' });
            setHorizontalGuide(horizontalGuideRef.current);
          }
        } else {
          if (horizontalGuideRef.current) {
            horizontalGuideRef.current.set({ stroke: 'red' });
            setHorizontalGuide(horizontalGuideRef.current);
          }
        }
        canvasRef.current.renderAll();
      }
    }
  };




    // Step 2: Define function to handle layer selection
    const handleLayerSelect = (options: fabric.IEvent) => {
      const selectedObjects = options.selected;

      if (selectedObjects && selectedObjects.length > 0) {
        const selectedObject = selectedObjects[0];
        if (selectedObject && selectedObject.name) {
          setSelectedLayerId(selectedObject.name);
          handleGuidelines(selectedObject); // Call the new function
        } else {
          setSelectedLayerId(null);
        }
      }
    };

    useEffect(() => {
    }, [selectedLayerId]);

    const handleTextColorChange = (newColor: string, id: string) => {
      // Find the layer with the given id
      const layerIndex = layersConfig.findIndex(layer => layer.id === id);
      if (layerIndex === -1) {
        return;
      }
    
      // Update the color of the layer
      const updatedLayers = [...layersConfig];
      updatedLayers[layerIndex] = {
        ...updatedLayers[layerIndex],
        color: newColor,
      };
    
      setLayersConfig(updatedLayers);
    
      // Update the color of the text on the canvas
      const canvasObject = canvasRef.current?.getObjects().find(obj => obj.name === id);
      if (canvasObject && canvasObject.type === 'text') {
        canvasObject.set({ fill: newColor });
        canvasRef.current?.renderAll();
      }
    };

    const setBackgroundImageFunc = (canvas: fabric.Canvas, url: string) => {
      return new Promise((resolve, reject) => {
        fabric.Image.fromURL(url, (img) => {
          if (img) {
            const scaleX = canvas.width ? canvas.width / (img?.width || 1) : 1;
            const scaleY = canvas.height ? canvas.height / (img?.height || 1) : 1;
            img.scaleX = scaleX;
            img.scaleY = scaleY;
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
            resolve(null);
          } else {
            reject('Failed to load image');
          }
        });
      });
    };
    
    // const setCanvasBackground = (url: string) => {
    //   if (canvasRef.current) {
    //     setBackgroundImageFunc(canvasRef.current, url)
    //       .catch((error) => console.error('Failed to set background image:', error));
    //   }
    // };
    
    const colorRect = React.useRef<fabric.Rect | null>(null);

    const handleCanvasColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCanvasColor(e.target.value);
      if (canvasRef.current) {
        // Clear the background image
        canvasRef.current.setBackgroundImage('', canvasRef.current.renderAll.bind(canvasRef.current));
    
        // Remove the old rectangle if it exists
        if (colorRect.current) {
          canvasRef.current.remove(colorRect.current);
          colorRect.current = null;
        }
    
        // Create a new rectangle and add it to the canvas
        colorRect.current = new fabric.Rect({
          width: canvasRef.current.width,
          height: canvasRef.current.height,
          fill: e.target.value,
          originX: 'left',
          originY: 'top',
          selectable: true,
          evented: false,
        });
        if (colorRect.current) {
          canvasRef.current.add(colorRect.current);
          canvasRef.current.sendToBack(colorRect.current);
        }
        canvasRef.current.renderAll();
      }
    };

    

    useEffect(() => {
    
      if (canvasRef.current && isCanvasReady) {
        canvasRef.current.on('object:moving', (e) => {
          if (e.target) {
            handleGuidelines(e.target);
          }
        });
        canvasRef.current.on('selection:created', (options) => {
          handleLayerSelect(options);
          if (verticalGuideRef.current) {
            verticalGuideRef.current.visible = true;

          }
          if (horizontalGuideRef.current) {
            horizontalGuideRef.current.visible = true;
          }
          if (canvasRef.current) {
            canvasRef.current.renderAll(); // This line is necessary to update the canvas after changing the visibility of the guidelines
          }
        });
        
        canvasRef.current.on('selection:cleared', () => {
          setSelectedLayerId(null);
          if (verticalGuideRef.current) {
            verticalGuideRef.current.visible = false;

          }
          if (horizontalGuideRef.current) {
            horizontalGuideRef.current.visible = false;

          }
          if (canvasRef.current) {

            canvasRef.current.renderAll(); // This line is necessary to update the canvas after changing the visibility of the guidelines
          }
        });
      }
      return () => {
        if (canvasRef.current) {
          canvasRef.current.off('selection:created', handleLayerSelect);
          canvasRef.current.off('selection:updated', handleLayerSelect);
          canvasRef.current.off('selection:cleared', () => setSelectedLayerId(null));
          canvasRef.current.off('object:moving');
        }
      };
    }, [canvasRef, isCanvasReady]);

    // Step 4: Define function to render layer controls
    const renderLayerControls = (layer: LayerConfig) => {

      // if (layer.id !== selectedLayerId) {

      //   return null;
      // }
      // Find the layer in the state
      const layerInState = layersConfig.find(l => l.id === layer.id);
      if (!layerInState) {
        return null;
      }

      if (layerInState.type === 'text' && layerInState.id) {
        if (layerInState.lockcolor) {
          // If lockcolor is true, do not render the control

          return null;
        } else if (layerInState.color_options && layerInState.color_options.length > 0) {
          // If color_options has values, render a radio button for each option

          return (
          <div key={layerInState.id} className="canvas-control" style={{ display: 'inline-flex', alignItems: 'center' }}>
            <label>Text Color  </label>
            <div style={{ display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center', marginLeft:'5px'}}>
              {layerInState.color_options.map((color, index) => (
                <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div 
                    style={{
                      backgroundColor: color,
                      width: '15px',
                      height: '15px',
                      borderRadius: '50%',
                      border: layerInState.color === color ? '1px solid black' : '1px solid grey',
                      // boxShadow: layerInState.color === color ? '0px 0px 5px black' : 'none',
                      cursor: 'pointer'
                    }}
                    onClick={() => layerInState.id && handleTextColorChange(color, layerInState.id)}
                  />
                </div>
              ))}
            </div>
          </div>
          );
        } else {
          // Else, render the color control as default

          return (
            <div key={layerInState.id}>
              <label>Text Color:</label>
              <input type="color" value={layerInState.color} onChange={(e) => layerInState.id && handleTextColorChange(e.target.value, layerInState.id)} />
            </div>
          );
        }
      }
      // Add controls for other layer types as needed
      return null;
    };
  

  useEffect(() => {
    const updateCanvasSize = () => {
      if (canvasRef.current && containerRef.current) {
        const width = containerRef.current.offsetWidth;
        canvasRef.current.setWidth(width);
        canvasRef.current.setHeight(width);
        canvasRef.current.calcOffset();
        canvasRef.current.renderAll();
        setCanvasReady(true);
      }
    };

    if (!canvasRef.current) {
      const newFabricCanvas = new fabric.Canvas('myCanvas');
      canvasRef.current = newFabricCanvas;
      updateCanvasSize();
      setCanvasReady(true);
    }

    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, [canvasRef]);

    // Add layers to the canvas
    useEffect(() => {
      if (canvasRef.current) {
        Promise.all(layersConfig.map((layer) => addLayer(canvasRef.current!, layer, personalisation)))
        .then(() => {
          // All layers have been added, move the text to the top
          const textObject = canvasRef.current!.getObjects('text')[0];
          canvasRef.current!.bringToFront(textObject);
          // Now render the canvas
          canvasRef.current!.renderAll();
          // Set the canvas color
          canvasRef.current!.setBackgroundColor(canvasColor, canvasRef.current!.renderAll.bind(canvasRef.current!));
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
      }
    }, [canvasRef, layersConfig, personalisation, canvasColor]); // Add personalisation to the dependency array

  return (
    <>
      {/* Step 6: Add control for canvas background color */}
      {/* <BackgroundGenerator setCanvasBackground={setCanvasBackground} /> */}
      <div className="canvas-control">
        <label htmlFor="backgroundPicker">Background Color</label>
        <input id="backgroundPicker" type="color" value={canvasColor} onChange={handleCanvasColorChange} /> 
      </div>
      {/* Step 5: Render controls for each layer */}
      {layersConfig.map(renderLayerControls)}
      {/* ... rest of your render method */}
      <div 
        ref={containerRef} 
        style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          width: '100%', 
          boxSizing: 'border-box'
          
        }}
      >
        <canvas id="myCanvas" style={{ width: '100%', height: '100%' }}></canvas>
        <div>
          <ExportButton fabricCanvas={canvasRef.current} /> 
          {canvasRef.current && <ShareButton canvasTitle={canvasTitle} canvasUser={canvasUser} fabricCanvas={canvasRef.current} />}
        </div>
      </div>
    </>
  );
};

export default Canvas;